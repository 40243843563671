<template>
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="inputVal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined rounded dense ref="myInput" hint="dd-mm-yyyy"
              persistent-hint :value="computedDateFormatted" readonly @input="handleInput()" :label="label" :name="name" :rules="formRules"  append-icon="mdi-calendar" v-bind="attrs"
                v-on="on"></v-text-field>
        </template>
        <v-date-picker
         v-model="date"
         :min="min"
          @input="$refs.dialog.save(date);modal = false"
        ></v-date-picker>
      
    </v-dialog>
</template>
<script>
    export default {
         props: ['label', 'name', 'value','validation','max','min'],
        name: 'formInput',
        data() {
            return {
                 formRules: [],
                 content:this.value,
                  date: this.value,
                modal: false,
                menu2: false,
            };
        },
         computed: {
             inputVal: {
                get() {
                    this.date = this.value;
                    //  console.log(this.value);
                    //    return this.formatDate(this.$refs.myInput.value)
                return this.value;
                },
                set(val) {
                this.$emit('input', val);
                }
            },
            computedDateFormatted () {
                return this.formatDate(this.date)
             
            },
        },
        watch: {
            date (val) {
                this.dateFormatted = this.formatDate(this.date)
            },
        },
        methods: {
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            setFormRules() {
                
                let validate = this.validation; 
                let tmp_validate = [];          
                
                if (validate) {
                     validate.forEach(rule => {
                        switch (rule) {
                            case 'required':
                                tmp_validate.push(v => !!v || 'Tidak Boleh Kosong');
                            break;
                           
                        }
                    });
                }            
              
                 this.formRules = tmp_validate;
            },
            handleInput (e) {
                // this.date = this.formatDate(this.$refs.myInput.value);
                this.$emit('input', this.formatDate(this.$refs.myInput.value))
            }
        },
        mounted() {
           this.setFormRules();
        //    this.date = this.value;
        //    console.log(this.value);
        },
       
    }
</script>